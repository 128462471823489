import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import UnorderedList from "~/components/List/Unordered";
import Button from "~/components/button";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={7}
        lesson={"Lesson 1"}
        color={"hs"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 7</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={true}
              setFullScreen={setStudentFullScreen}
            >
              <p>Now that you have evaluated the different websites, be prepared to discuss and ask questions about the criteria listed on the rubric and the ones that you added to your rubric.</p>
              <p>You are also now ready to think about a score for website features for each of the 3 sites.</p>
              <p>Your teacher will lead a discussion about how to assign a score on your rubric for each website.</p>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={true}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Now that students have finished coding the features of each website, hold a class discussion to talk about the statements they wrote to complete this category of the rubric. If helpful, you can project a copy of the rubric during the discussion.</p>

              <div>
                <Button
                  action="secondary"
                  className="m-2"
                  color="indigo"
                  href="/files/hs/lesson_1/HS__S_full_rubric-scl_final.aw.docx"
                  icon="download"
                  title="Lesson 7 rubric"
                >
                  Rubric
                </Button>
              </div>

              <p>Students should write statements that correspond to websites including dates, websites having functional links, and websites including contact information. Encourage students to write statements that can be answered either yes or no.</p>
              <p>In this lesson (or later lessons when writing criteria for their rubrics), students should phrase their questions so that a "yes" answer is the desired outcome. This will ensure that for all statements, the applicable score would be the highest score. Scoring would be compromised if the desired outcomes for all questions was a 5 rather than some being a 5 and others being a 1.</p>
              <p>Students should have written statements similar to the following:</p>

              <UnorderedList>
                <li>The website includes a date for when it was created or updated.</li>
                <li>The site includes information about how to contact the author or organization.</li>
                <li>The links to other pages within the site work correctly.</li>
              </UnorderedList>

              <p>Continue by discussing how to assign a score on the rubric for this overall category of website quality and initial screening. The scoring on this rubric may be done differently than on other rubrics students have used. Points to bring out in the discussion include the following:</p>

              <UnorderedList>
                <li>A site that meets all or most of the criteria on the rubric should get a higher score than a site that does not include many features or a site on which the features are not handled well.</li>
                <li>The score is for the entire category on the rubric (all 8 criteria). It should not be just a checklist to count the number of features.</li>
                <li>Students do not need to agree with their teammates on a score.</li>
                <li>It is important to be able to explain your score. The last column on the rubric includes space to write reasons for why a score is justified.</li>
                <li>Scores can be changed if desired. If a student learns new information or interprets information in a different way, it is OK to change the score to reflect that new understanding.</li>
              </UnorderedList>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
